import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/home/Home.vue";
import Login from "../views/login/Login.vue";
import Initial from "../views/initial/Initial";
import VerifyCode from "../views/verify-code/VerifyCode";
import ProgramList from "../views/program-list/ProgramList";

import { viewRestoration } from "../helpers/viewRestoration";
const routes = [
  {
    path: "/",
    name: "Initial",
    component: Initial
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/verify-code",
    name: "VerifyCode",
    component: VerifyCode
  },
  {
    path: "/program-list",
    name: "ProgramList",
    component: ProgramList
  },
  {
    path: "/home",
    name: "NominationWizard",
    component: Home
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

viewRestoration(router);

export default router;
