<template>
  <div class="form-error" v-if="message !== ''">
    <div>{{ message }}</div>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
  },
};
</script>

<style lang="scss">
@import "./../../styles/index.scss";
</style>

<style scoped>
@import "./error-message.scss";
</style>
