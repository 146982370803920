<template>
  <div class="wrapper">
    <div class="logo">
      <img
        :src="programLogo || logoPath"
        @error="setFallbackLogo"
        alt="logo"
        width="131"
      />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { LOGO_PATH, LOGO_FALLBACK } from "../../constants";

export default {
  props: ["programLogo"],
  data() {
    return {
      hasImageError: false,
      logoPath: LOGO_PATH || LOGO_FALLBACK,
    };
  },
  methods: {
    setFallbackLogo(event) {
      if (!this.hasImageError) {
        event.target.src = LOGO_FALLBACK;
      } else {
        event.target.hidden = true;
      }
      // If we try to load the fallback logo we make a note of that
      // so next time we can hide the image tag instead of triggering
      // an infinite loop.
      this.hasImageError = true;
    },
  },
};
</script>

<style lang="scss">
@import "./../../styles/index.scss";
</style>

<style scoped>
@import "./layout.scss";
</style>
