<template>
  <div class="loading">
    <div class="loading-icon"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import "./loading.scss";
</style>
