/**
 * Program URL returned after successful login.
 * Used to attach the /dash/<id> path when loading the page in an iFrame.
 */
export const URL = "url";
export const USED = "used";
export const APPOINTMENT = "appointment";
export const MESSAGE = "message";
export const FAILED = "failed";
/**
 * We need to store last viewed page to be able to restore it when opening the add-in.
 * It is necessary to validate the code in email-based login workflow.
 * Without the last viewed page closing and opening the extension brings
 * user to the login workflow selection page not giving them a chance to enter the code.
 */
export const LAST_VIEWED_PAGE = "lastViewedPage";
/**
 * Key used to store the program domain that is passed as a proxy target.
 */
export const PROGRAM_ID = "programId";
export const DOMAIN_NAME = "domain";
// Regex used for validating the program domain entered by the user in program domain workflow.
export const VALIDATION_DOMAIN =
  "^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$";
// Regex used for validating e-mail address provided by the user in e-mail based login workflow.
export const VALIDATION_EMAIL = "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$";

/****** Look and Feel  *********/
export const LOGO_FALLBACK = "/logo-power-2-motivate.svg";
export const LOGO_PATH = process.env.VUE_APP_LOGO_PATH;
export const VALIDATION_CODE_TIMEOUT_IN_SECONDS =
  process.env.VUE_APP_VALIDATION_CODE_TIMEOUT_IN_SECONDS || 1800; // 30 miuntes
export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const CSRF = '_csrf';

/****** Custom theme  *********/
export const APP_PRIMARY_COLOR = process.env.VUE_APP_PRIMARY_COLOR || ''
export const APP_FONT_COLOR = process.env.VUE_APP_FONT_COLOR || ''
export const APP_BUTTON_TEXT_COLOR = process.env.VUE_APP_BUTTON_TEXT_COLOR || ''
export const APP_SITE_BACKGROUND_COLOR = process.env.VUE_APP_SITE_BACKGROUND_COLOR || ''
