import { FAILED } from "../../constants";

export const processEmail = ({ to, cc, bcc }) => {
  const toRecipients = [],
    ccRecipients = [],
    bccRecipients = [];

  to.map((item) => {
    const { displayName, emailAddress, recipientType } = item;
    toRecipients.push({
      displayName,
      emailAddress,
      recipientType,
    });
  });

  cc.map((item) => {
    const { displayName, emailAddress, recipientType } = item;
    ccRecipients.push({
      displayName,
      emailAddress,
      recipientType,
    });
  });

  bcc.map((item) => {
    const { displayName, emailAddress, recipientType } = item;
    bccRecipients.push({
      displayName,
      emailAddress,
      recipientType,
    });
  });

  return {
    toRecipients,
    ccRecipients,
    bccRecipients,
  };
};

export const getToRecipients = () => {
  var Office = window.Office;

  return new Promise((resolve, reject) =>
    Office.context.mailbox.item.to.getAsync(function (asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        resolve(asyncResult.value);
      } else {
        reject(asyncResult.error);
      }
    })
  );
};

export const getCCRecipients = () => {
  var Office = window.Office;

  return new Promise((resolve, reject) =>
    Office.context.mailbox.item.cc.getAsync(function (asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        resolve(asyncResult.value);
      } else {
        reject(asyncResult.error);
      }
    })
  );
};

export const getBCCRecipients = () => {
  var Office = window.Office;

  return new Promise((resolve, reject) =>
    Office.context.mailbox.item.bcc.getAsync(function (asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        resolve(asyncResult.value);
      } else {
        reject(asyncResult.error);
      }
    })
  );
};

export const getRequiredAttendees = () => {
  var Office = window.Office;

  return new Promise((resolve, reject) =>
    Office.context.mailbox.item.requiredAttendees.getAsync(function (
      asyncResult
    ) {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        resolve(asyncResult.value);
      } else {
        reject(asyncResult.error);
      }
    })
  );
};

export const getOptionalAttendees = () => {
  var Office = window.Office;

  return new Promise((resolve, reject) =>
    Office.context.mailbox.item.optionalAttendees.getAsync(function (
      asyncResult
    ) {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        resolve(asyncResult.value);
      } else {
        reject(asyncResult.error);
      }
    })
  );
};

/**
 * Attempts to read the token from Microsoft Office. It is then used for token based single sign-on
 * @returns Promise<string|Error> - Returns authentication token from Microsoft Outlook or Error
 */
export const getSSOToken = () => {
  var Office = window.Office;

  return new Promise((resolve, reject) =>
    Office.context.auth.getAccessTokenAsync(
      { allowConsentPrompt: true },
      (tokenObject) => {
        const { status, value, error } = tokenObject;
        if (status == FAILED) {
          reject(error);
        }
        resolve(value);
      }
    )
  );
};
