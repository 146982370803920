<template>
  <div>
    <div v-if="loading" class="container h-100">
      <div class="d-flex justify-content-center align-items-center vh-100">
        <div
          class="spinner-border text-dark"
          style="width: 4rem; height: 4rem"
        ></div>
      </div>
    </div>
    <div class="iframe-window">
      <div class="sign-out"><span @click="signOut">Log Out</span></div>
      <iframe
        v-if="showIframe"
        name="nomination-wizard"
        id="nomination"
        width="100%"
        height="100%"
        frameBorder="0"
        allow="camera; microphone"
        :src="iframSrc"
        @load="postMessageToIframe"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
import {
  getToRecipients,
  getCCRecipients,
  getBCCRecipients,
  getRequiredAttendees,
  getOptionalAttendees,
  processEmail,
} from "./helpers";
import { deleteCookie } from "../../helpers/cookies";
import {
  URL,
  APPOINTMENT,
  MESSAGE,
  DOMAIN_NAME,
  PROGRAM_ID,
  LAST_VIEWED_PAGE,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CSRF
} from "../../constants";
export const NOMINATION_WIZARD_PATH =
  process.env.VUE_APP_NOMINATION_WIZARD_PATH;

let recipients = [];
let Office = window.Office;

export default {
  name: "App",
  beforeUnmount() {
    clearInterval(this.keepAliveInterval);
  },

  beforeMount: async function () {
    //add listenerEvents
    window.addEventListener("message", (message) => {
      if (message.data.event === "widgetInitialized") {
        this.keepAliveInterval = this.keepAlive();
        this.postMessageToIframe();
      }
      // ...continueSession
    });
  },
  created() {
    this.pullRecipients();
    const url = localStorage.getItem(URL);

    if (url) {
      this.iframSrc = this.src + url;
      this.showIframe = true;
    } else {
      this.$router.push({ path: "/" });
    }

     Office.context.mailbox.item.addHandlerAsync(Office.EventType.RecipientsChanged, this.pullRecipients, function () {
      this.pullRecipients(true);
    });
  },
  data() {
    return {
      loading: true,
      showIframe: false,
      iframSrc: null,
      src: localStorage.getItem(DOMAIN_NAME),
    };
  },
  methods: {
    signOut() {
      this.postMessageDispatcher("signout");
      setTimeout(() => {
	    this.clearSession();
      }, 500);
    },
    pullRecipients(repost) {
      Office.onReady(async (info) => {
        if (info.host === Office.HostType.Outlook) {
          var item = Office.context.mailbox.item;

          if (item.itemType == MESSAGE) {
            if (item.to.getAsync) {
              const to = await getToRecipients();
              const bcc = await getBCCRecipients();
              const cc = await getCCRecipients();
              recipients = [...to, ...cc, ...bcc];
            } else {
              const { toRecipients, ccRecipients, bccRecipients } =
                processEmail({
                  to: item.to,
                  cc: item.cc,
                  bcc: item.bcc,
                });

              recipients = [...toRecipients, ...ccRecipients, ...bccRecipients];
            }
          }

          if (item.itemType == APPOINTMENT) {
            if (item.requiredAttendees.getAsync) {
              const requiredAttendees = await getRequiredAttendees();
              const optionalAttendees = await getOptionalAttendees();
              recipients = [...requiredAttendees, ...optionalAttendees];
            } else {
              const { requiredAttendees, optionalAttendees } = item;
              recipients = [...requiredAttendees, ...optionalAttendees];
            }
          }
          if (repost) {
            this.postMessageToIframe();
          }
        }
      });
    },
    keepAlive() {
      const payload = {
        event: "heartbeat",
        params: {
          notUsePreLoader: true,
        },
      };

      const iframeWin = document.getElementById("nomination").contentWindow;

      return setInterval(() => {
        iframeWin.postMessage(payload, "*");
      }, 5000);
    },
    postMessageToIframe() {
      const payload = {
        event: "addNomineesByEmails",
        params: recipients,
      };
      this.loading = false;
      var iframeWin = document.getElementById("nomination").contentWindow;
      iframeWin.postMessage(payload, `${this.src}/${NOMINATION_WIZARD_PATH}`);
    },
    postMessageDispatcher(event, params = {}, options = {}) {
      const payload = {
        event,
        params,
      };
      if (options.loading) {
        this.loading = true;
      } else {
        this.loading = false;
      }

      const iframeWin = document.getElementById("nomination").contentWindow;
      iframeWin.postMessage(payload, `${this.src}/${NOMINATION_WIZARD_PATH}`);
    },
    // After logout we need to clear the data from localStorage
    // and redirect user to the program selection page.
    clearSession() {
      localStorage.removeItem(DOMAIN_NAME);
      localStorage.removeItem(PROGRAM_ID);
      localStorage.removeItem(LAST_VIEWED_PAGE);
      localStorage.removeItem(URL);
      deleteCookie(ACCESS_TOKEN);
      deleteCookie(REFRESH_TOKEN);
      deleteCookie(CSRF);
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style scoped>
@import "./home.scss";
</style>
