/**
 * @description
 * API's specific to the specific program.
 * Those API's require a valid DOMAIN_NAME to be provided.
 */
import { HTTP } from "../base";
import { domainStringToURL, domainStringToFQDN } from "./../utils";
import { DOMAIN_NAME } from "../../constants";
/**
 * @typedef {object} ProgramDetail
 * @property {number} id
 * @property {number} partnerId
 * @property {string} name
 * @property {string} companyName
 * @property {null} companyUrl
 * @property {number} skinId
 * @property {string} provinceState
 * @property {string} country
 * @property {number} registerEnableRegister
 * @property {number} registerEnableForgot
 * @property {number} resellerId
 * @property {number} pointBillingRate
 * @property {number} accountFreezeFlag
 * @property {number} rewardStyle
 * @property {string} plateauContext
 * @property {null} showProgramdetailsFlag
 * @property {string} note
 * @property {number} pointsFormulaId
 * @property {boolean|null} hideProgramLogoFlag
 * @property {string|null} companyLogo
 * @property {string} programLogo
 * @property {number} isDisabled
 * @property {number} flags
 * @property {null} gaAccount
 * @property {number} requireTandc
 * @property {string} defaultLanguage
 * @property {number} grsGalleryId
 * @property {string} grsGalleryCurrencyCode
 * @property {string} code
 * @property {string} programType
 * @property {string} defaultOperatingCurrencyCode
 */

export const NOMINATION_WIZARD_PATH =
  process.env.VUE_APP_NOMINATION_WIZARD_PATH;
const HIDE_HEADER_OPTIONS =
  process.env.VUE_APP_HIDE_HEADER_OPTIONS ||
  "dreamtracker, myaccount, gallery, myorders, logo, topbar, cart";
const LAYOUT = process.env.VUE_APP_LAYOUT || 0;

/**
 * Begins the session for the current user interaction with the program.
 * At this point we don't know yet if user is authenticated or not.
 * This request will just set the cookie for the user so the consecutive
 * request will be on the same session.
 *
 * NOTE: Session initialization is scoped to the program URL and subprogramId.
 * Before initializing the session we must set DOMAIN_NAME local storage value.
 * The DOMAIN_NAME value will be passed to the proxy server for further interactions.
 * @param {string} subprogramId Unique identifier for the program.
 * @returns Promise<>
 */
export const initialize = (subprogramId) => {
  return HTTP.get(`/api/v1/Subprograms/init?subprogramId=${subprogramId}`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      url: domainStringToFQDN(localStorage.getItem(DOMAIN_NAME)),
    },
  });
};

// eslint-disable-next-line no-unused-vars
export const resetPassword = ({ userName }) => {
  return new Promise((resolve) =>
    setTimeout(
      () =>
        resolve({
          success: true,
          message: "Rest email has been sent",
        }),
      3000
    )
  );
};

/**
 * Attempts to authenticate already initialized session. @see `initialize`
 * @param {Object} loginPayload
 * @param {string} loginPayload.memberId - Unique identifier of the memeber
 * @param {string} loginPayload.password - User password
 * @param {Object} loginConfiguration
 *
 * @returns Promise<>
 */
export const login = (
  { token },
  { layout, redirect, hideHeaderOptions } = {
    layout: LAYOUT,
    redirect: NOMINATION_WIZARD_PATH,
    hideHeaderOptions: HIDE_HEADER_OPTIONS,
  }
) => {
  return HTTP.get("/api/v1/Sso/getSsoSession", {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      url: domainStringToFQDN(localStorage.getItem(DOMAIN_NAME)),
      "Authorization": `Bearer ${token}`
    },
    params: {
      ssoOptions: {
        layout,
        redirect,
        hideHeaderOptions,
      },
    },
  });
};

/**
 * @description Retrieve the program information by domain name
 * @param {Object} payload
 * @param {string} payload.domainName - Name of the program domain to validate. Must be full name with protocol: i.e. https://example.com
 * @returns Promise<ProgramDetail|false>
 */
export const verifyDomainName = ({ domainName }) => {
  const programURL = domainStringToURL(domainName);
  if (programURL instanceof URL === false) {
    throw new Error(`Invalid domain name`);
  }
  return HTTP.get("/api/v1/Subprograms/findByHost", {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      url: domainStringToFQDN(localStorage.getItem(DOMAIN_NAME)),
    },
    params: {
      host: programURL?.host,
    },
  }).then((response) => {
    response.data.url = programURL.origin;
    return response;
  });
};
/**
 * Retrive the subprogram configuration. Including logo, password reminders, etc.
 *
 * @returns Promise<Object>
 */
export const getSubprogramConfig = () => {
  return HTTP.get(`/api/v1/SubprogramConfigurations`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      url: domainStringToFQDN(localStorage.getItem(DOMAIN_NAME)),
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data) {
          console.error(error.response.data.message);
        }
      }
    });
};
/**
 * Attempts to log-in using Microsoft provided token
 * @param {Object} payload - ssoTokenAuth payload
 * @param {string} payload.domain - program domain name to validate
 * @param {string} payload.token - Microsoft provided authentication token
 */
export const ssoTokenLogin = (
  { domain, token },
  { layout, redirect, hideHeaderOptions } = {
    layout: LAYOUT,
    redirect: NOMINATION_WIZARD_PATH,
    hideHeaderOptions: HIDE_HEADER_OPTIONS,
  }
) => {
  const programURL = domainStringToURL(domain);
  if (programURL instanceof URL === false) {
    throw new Error(`Invalid domain name`);
  }
  return HTTP.get("/azure-sso/validate", {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    params: {
      domain: `${programURL.protocol}//${programURL?.host}`,
      sso_options: {
        layout,
        redirect,
        hideHeaderOptions,
      },
    },
  });
};
