<template>
  <button :id="id" @click="$emit('onclick')">
    {{ name }}
  </button>
</template>
<script>
export default {
  props: ["name", "backgroundColor", "textColor", "id"],
  mounted() {
    if (!this.id) return;
    const elem = document.getElementById(this.id);
    if (this.backgroundColor) {
      elem.style.backgroundColor = this.backgroundColor;
      elem.style.color = this.textColor || "#fff";
    }
  },
  watch: {
    backgroundColor: {
      handler(newVal) {
        const elem = document.getElementById(this.id);
        if (newVal) {
          elem.style.backgroundColor = newVal;
        }
      },
    },
    textColor: {
      handler(newVal) {
        const elem = document.getElementById(this.id);
        if (newVal) {
          elem.style.color = newVal || "#fff";
        }
      },
    },
  },
};
</script>
<style scoped>
button {
  margin-top: 20px;
  background: #f26933;
  color: #fff;
  border-radius: 5px;
  width: 100%;
  font-size: 15px;
  line-height: 19px;
  font-weight: 500;
  height: 41px;
  border: transparent !important;
  outline: transparent !important;
  cursor: pointer;
}
@media screen and (max-width: 864px) {
  button {
    font-size: 14px;
  }
}
</style>
