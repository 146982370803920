/**
 * @description Determines the starting page for the application
 * based on the last viewed page. It is needed to implement
 * verification code functionality in Outlook client.
 * When verification code is received user might need to change the
 * active email to the one with code, that will close the extension,
 * opening it again will start from login workflow selection page.
 * This logic remembers last viewed page and if it's `verifyCode`
 * the application will start from that page.
 */

import { LAST_VIEWED_PAGE } from "../constants";

// Stores the last visited page based on the router navigation
// and tries to restore it if it's the verify code page.
export const viewRestoration = (router) => {
  router.afterEach((to, from, failure) => {
    console.group("page navigation");
    console.debug("to: ", to);
    console.debug("from: ", from);
    console.debug("failure: ", failure);
    console.groupEnd("page navigation");
    localStorage.setItem(LAST_VIEWED_PAGE, JSON.stringify(to));
  });

  const getLastViewedPage = () => {
    try {
      const lastViewedPageState = localStorage.getItem(LAST_VIEWED_PAGE);

      if (lastViewedPageState) {
        const result = JSON.parse(lastViewedPageState);
        return result;
      }
    } catch (error) {
      console.error(
        "[getLastViewedPage]: unable to parse last viewed page: ",
        error
      );
    }
  };

  // In order for the user to successfuly verify the code,
  // We need to start from the verifiy code page.
  // Otherwise the add-in will restart the from the login flow
  // selection process.
  const setStartPage = (router, lastViewedPage) => {
    if (lastViewedPage && lastViewedPage?.name === "VerifyCode") {
      router.replace(lastViewedPage.fullPath);
    }
  };

  setStartPage(router, getLastViewedPage());
};
