export function setCookie(name, value, expiry) {
  let now = new Date();
  let time = now.getTime();
  time += expiry || (3600 * 24 * 1000);
  now.setTime(time);
  document.cookie = `${name}=${value};expires=${now.toUTCString()};SameSite=None;Secure;path=/`;
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

export function deleteCookie( name ) {
  document.cookie = `${name}=;max-age=0;SameSite=None;Secure;`;
}