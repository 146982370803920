<template>
  <div class="form-input" v-bind:style="{outlineColor: themeColor, borderColor: themeColor}">
    <div class="input" :error="showError">
      <label>{{ label }}</label>
      <slot name="startAdornment"></slot>
      <input
        v-model="model"
        :type="type"
        :placeholder="placeholder"
        :pattern="pattern"
        :required="required"
        @invalid.prevent="onInvalid"
        @submit="onSubmit"
      />
    </div>
    <ErrorMessage :message="error" />
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    type: {
      type: String,
      default: 'text',
    },
    placeholder: String,
    errorMessage: String,
    pattern: String,
    required: Boolean,
    modelValue: String,
    themeColor: String,
  },
  data() {
    return {
      error: '',
    }
  },
  computed: {
    showError() {
      return this.error !== ''
    },
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  methods: {
    onInput(event) {
      this.error = event.target.validity.valid
        ? ''
        : event.target.validity.valueMissing
        ? ''
        : this.errorMessage
      this.$emit('input', event.target.value)
    },
    onInvalid(event) {
      this.error = event.target.validity.valid
        ? ''
        : event.target.validity.valueMissing
        ? ''
        : this.errorMessage
      event.target.setCustomValidity('')
      this.$emit('invalid', event.target.value)
    },
    onSubmit(event) {
      this.error = event.target.validity.valid
        ? ''
        : event.target.validity.valueMissing
        ? ''
        : this.errorMessage
      event.target.setCustomValidity('')
      this.$emit('submit', event.target.value)
    },
  },
}
</script>

<style lang="scss">
@import './../../styles/index.scss';
</style>

<style scoped>
@import './input.scss';
</style>
