/**
 *
 * @param {string} domain domain name to standardize
 * @returns Domain name prefixed with `https`
 */
export const domainStringToFQDN = (domain) => {
  let fqdn = domain;
  if (!fqdn.toLowerCase().startsWith("http")) {
    fqdn = `https://${fqdn}`;
  }

  return fqdn;
};

export const domainStringToURL = (domain) => {
  const fqdn = domainStringToFQDN(domain);

  const url = new URL(fqdn);
  return url;
};

export const switchProgram = (router) => {
  // clear local storage & redirect to home page
  router.push({
    path: "/login",
  });
};
