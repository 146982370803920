<template>
  <router-view />
</template>

<script>
import {
  APP_PRIMARY_COLOR,
  APP_FONT_COLOR,
  APP_BUTTON_TEXT_COLOR,
  APP_SITE_BACKGROUND_COLOR
} from './constants'

export default {
  methods: {
    setTheme() {
      let r = document.querySelector(':root')
      if (APP_PRIMARY_COLOR) {
        r.style.setProperty('--primary', APP_PRIMARY_COLOR)
      }
      if (APP_FONT_COLOR) {
        r.style.setProperty('--font-color-default', APP_FONT_COLOR)
      }
      if (APP_BUTTON_TEXT_COLOR) {
        r.style.setProperty('--button-text-color-default', APP_BUTTON_TEXT_COLOR)
      }
      if (APP_SITE_BACKGROUND_COLOR) {
        r.style.setProperty('--site-background', APP_SITE_BACKGROUND_COLOR)
      }
    }
  },
  created() {
    this.setTheme()
  }
}
</script>
